import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Footer } from "landing/Footer";
import { Header } from "landing/Header";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Nigeria",
            description: (
              <>
                <Address>
                  <AddressLine>51 G Line, Ewet Housing</AddressLine>
                  <AddressLine>Uyo, Akwa Ibom</AddressLine>
                </Address>
                <Email>contact@venmiga.com</Email>
                <Phone>+234 (0) 8035336810</Phone>
              </>
            )
          },
          
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
