import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Venmiga from "Venmiga";
import ComponentPreview from "venmiga/ComponentPreview";
import SitePreview from "venmiga/SitePreview";
import TermsOfService from "pages/TermsOfService";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ContactUs from "pages/ContactUs";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const isDomainAllowed = () => {
    const hostname = window.location.hostname;
    return hostname === "venmiga.com" || hostname === "www.venmiga.com";
  };
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          {isDomainAllowed() ? (
            <>
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<ContactUs />} />
            </>
          ) : null}
          <Route
            path="/components/:componentId"
            element={<ComponentPreview />}
          />
          <Route path="/site/:pageId" element={<SitePreview />} />
          <Route path="*" element={<Venmiga />} />
        </Routes>
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
