import React from "react";
import { useNavigate } from "react-router-dom"; // Updated import

const Fullscreen404 = () => {
  const navigate = useNavigate(); // Updated hook

  const handleGoHome = () => {
    navigate("/"); // Updated navigation method
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.message}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <button style={styles.button} onClick={handleGoHome}>
        Go Home
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
    color: "#343a40",
    textAlign: "center",
  },
  heading: {
    fontSize: "6rem",
    margin: "0",
  },
  message: {
    fontSize: "1.5rem",
    margin: "20px 0",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Fullscreen404;
