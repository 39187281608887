import clsx from "clsx";

export function PlayStoreLink({ color = "black" }) {
  return (
    <a
      href="/"
      aria-label="Download on the App Store"
      className={clsx(
        "rounded-lg transition-colors",
        color === "black"
          ? "bg-gray-800 text-white hover:bg-gray-900 ml-1"
          : "bg-white text-gray-900 hover:bg-gray-50"
      )}
    >
      <img
        className="h-10"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/360px-Google_Play_Store_badge_EN.svg.png"
        alt="Google Play Store"
      />
    </a>
  );
}
